import React from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Paper, Grid } from '@mui/material';

function Ubicacion() {
    return (
        <Box sx={{ bgcolor: '#EFF4F5', py: 3, px: 15 }}>
            <Typography variant="h4" align="center" gutterBottom
            sx={{
                fontFamily: "'Poppins', sans-serif",
                fontWeight: 500,
                color: "#444444", // Gris oscuro
                mb: 0,
              }}>
                Vení a conocernos
            </Typography>
            <Typography variant="h6" align="center" sx={{ 
                color: '#c5a27d', 
                fontFamily: "'Poppins', sans-serif",
                fontWeight: 600,
                mb: 3 }}>
                TANTI, CÓRDOBA.
            </Typography>

            {/* Contenedor principal: Grid para mapa y tabla */}
            <Grid container spacing={1} justifyContent="center" alignItems="center">
                {/* Mapa */}
                <Grid item xs={12} md={6}>
                    <Box sx={{ width: '100%', maxWidth: 600, mx: 'auto' }}>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3406.589576809862!2d-64.59366792330447!3d-31.370303094027122!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x942d6e58b02a307d%3A0x3eeb257a345c86e1!2sComplejo%20Flor%20Serrana!5e0!3m2!1ses-419!2sar!4v1733286904809!5m2!1ses-419!2sar"
                            width="100%"
                            height="400"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            title="Google Maps"
                        ></iframe>
                    </Box>
                </Grid>

                {/* Tabla de lugares de interés */}
                <Grid item xs={12} md={6}>
                    <Box sx={{ maxWidth: 500, mx: '10%', textAlign: 'center', }}>
                    <Typography variant="h6" align="center" sx={{ 
                        color: '#444444', 
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 600,
                        mb: 0.2 }}>
                        Lugares de interés
                    </Typography>
                        <TableContainer component={Paper} sx={{ height: 215 }}>
                            <Table>
                                <TableBody>
                                    {[
                                        { lugar: 'Villa Flor Serrana', km: 2 },
                                        { lugar: 'Cascada Los Chorrillos', km: 7 },
                                        { lugar: 'Villa Carlos Paz', km: 14 },
                                        { lugar: 'Ciudad de Córdoba', km: 55 },
                                    ].map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">{row.lugar}</TableCell>
                                            <TableCell align="right">{row.km} KM</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Ubicacion;
