import React from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const BotonFlotanteWhatsApp = () => {
  return (
    <div
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: 1000,
      }}
    >
      <a
        href="https://wa.me/3512021498" // Cambia este número por el que corresponda
        target="_blank"
        rel="noopener noreferrer"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '60px',
          height: '60px',
          backgroundColor: '#25D366',
          borderRadius: '50%',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          color: '#fff',
          textDecoration: 'none',
        }}
      >
        <WhatsAppIcon style={{ fontSize: '35px'
        }}
        sx = {{ '&:hover': { transform: 'scale(1.2)', transition: '0.2s' }}} 
        />
      </a>
    </div>
  );
};

export default BotonFlotanteWhatsApp;
