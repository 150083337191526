import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Encabezado from './componentes/Encabezado';
import Principal from './paginas/Principal';
import PiePagina from './componentes/PiePagina';
import NuestroComplejo from './paginas/NuestroComplejo';
import BotonFlotante from './componentes/BotonFlotante';
import Ubicacion from './componentes/Ubicacion'

function App() {
    return (
      <Router>
        <Encabezado />
        <Routes>
          <Route path="/" element={<Principal />} />
          <Route path="/nuestro-complejo" element={<NuestroComplejo />} />
        </Routes>
        <Ubicacion />
        <BotonFlotante />
        <PiePagina />
      </Router>
    );
  }

export default App;