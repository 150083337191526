import React, { useEffect } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Box } from '@mui/material';
import { Cabin, WhatsApp as WhatsAppIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom'; // Importa Link


function Encabezado() {
  useEffect(() => {
    // Ajusta el margen superior global según la altura del encabezado
    const header = document.getElementById('header');
    if (header) {
      const headerHeight = header.offsetHeight;
      document.body.style.marginTop = `${headerHeight}px`; // Establece un margen dinámico
    }
  }, []);

   // Función para redirigir y hacer scroll hacia arriba NO SE USA
   const handleNavigation = (path) => {
    navigate(path); // Cambia de ruta
    window.scrollTo({ top: 0, behavior: "smooth" }); // Desplaza al inicio
  };

  return (
    <AppBar
    id="header"
    position="fixed"
    sx={{
      bgcolor: 'transparent',
      background: 'linear-gradient(90deg, #6a9c78, #6a9c78)',
      color: 'white',
      boxShadow: 8,
    }}
  >
  
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {/* Menú de la izquierda */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 3  , ml: 15 }}>
          <Box
            component={Link}
            to="/nuestro-complejo"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textDecoration: 'none',
              color: 'inherit',
              
              
            }}
          >
            <IconButton color="inherit" sx={{ scale: 1.2,
              mb: -0.7, 
              '&:hover': { transform: 'scale(1.1)', transition: '0.2s',
              
              },
            }}>
              <Cabin />
            </IconButton>
            <Typography
              variant="h6"
              sx={{
                mb: 1,
                fontFamily: 'Montserrat, sans-serif',
                fontWeight: '700',
                fontSize: '0.7rem',
                color: 'white',
                textTransform: 'uppercase',
                letterSpacing: '0.2em'
              }}
            >
              Nuestras Cabañas
            </Typography>
          </Box>

                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <IconButton
            color="inherit"
            sx={{
              scale: 1.2,
              mb: -0.7,
              '&:hover': { transform: 'scale(1.1)', transition: '0.2s' }, // Animación al pasar el cursor
            }}
            component="a"
            href="https://wa.me/3512021498"
            target="_blank"
          >
            <WhatsAppIcon />
          </IconButton>
          <Typography
            variant="h6"
            sx={{
              mb: 1,
              fontFamily: 'Montserrat, sans-serif',
              fontWeight: '700',
              fontSize: '0.7rem',
              color: 'white',
              textTransform: 'uppercase',
              letterSpacing: '0.3em',
              
            }}
            component="a"
            href="https://wa.me/3512021498"
            target="_blank"
            style={{ textDecoration: 'none' }}
          >
            Reservar
          </Typography>
        </Box>

        </Box>

      
<Box sx={{ textAlign: 'center', color: '#fff', mt: 1.5, mb: 1.5, transform: 'translateX(-70%)' }}>
  {/* Enlace completo */}
  <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
    {/* Línea superior */}
    <Typography
      variant="h6"
      sx={{
        fontSize: '1.4rem', // Tamaño del texto superior
        fontFamily: 'Playfair Display, serif', // Fuente clásica
        fontWeight: '400', // Peso del texto
        letterSpacing: '0.15em', // Espaciado moderno
        mb: -1, // Reducir el margen inferior
      }}
    >
      Flor Serrana
    </Typography>

    {/* Línea inferior */}
    <Typography
      variant="h4"
      sx={{
        fontSize: '1.1rem', // Tamaño del texto principal
        fontFamily: 'Playfair Display, serif',
        fontWeight: '700', // Más peso para destacar
        textTransform: 'uppercase', // Texto en mayúsculas
        letterSpacing: '0.1em', // Espaciado adicional
      }}
    >
      Complejo Turístico
    </Typography>
  </Link>
</Box>




        {/* Bandera */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <img
            src="https://flagcdn.com/ar.svg"
            alt="Idioma"
            style={{
              width: 28,
              height: 18,
              borderRadius: '2px', // Esquinas redondeadas
              boxShadow: '0 2px 4px rgba(0,0,0,0.2)', // Pequeña sombra
            }}
          />

        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Encabezado;
