import React from 'react';
import { Box, Typography, Link, IconButton } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Facebook } from '@mui/icons-material';

function Footer() {
    return (
        <Box sx={{ bgcolor: '#8f645b', color: 'white', py: 4, px: 2 }}>
            <Box 
                sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-around', 
                    flexWrap: 'wrap', 
                    gap: 4,
                    mb: 4 
                }}
            >
                {/* Sección de contacto */}
                <Box>
                    <Typography 
                        variant="h6" 
                        sx={{ 
                            fontFamily: "'Poppins', sans-serif",
                            mb: 1, 
                            fontWeight: 300,
                            textAlign: 'center', // Centrar el texto
                            fontSize: '1.5rem' // Ajustar el tamaño
                        }}
                    >
                        Contacto
                    </Typography>
                    <Typography
                        variant="h6" 
                        sx={{ 
                            mb: 2, 
                            textAlign: 'center', // Centrar el texto
                            fontSize: '0.9rem', // Ajustar el tamaño
                            fontFamily: "'Poppins', sans-serif",
                        }}    
                    >
                        Cno. A Flor Serrana Km 1, Tanti, Córdoba.
                    </Typography>

                    <Box
                        sx={{ 
                            display: 'flex', 
                            justifyContent: 'center', 
                            alignItems: 'center', 
                            gap: 2, 
                            flexWrap: 'wrap',
                            
                        }}
                    >
                        <Typography
                            sx={{
                                display: 'flex', 
                                alignItems: 'center',
                                fontFamily: "'Poppins', sans-serif",
                            }}
                        >
                            <PhoneIcon sx={{ mr: 1 }} />
                            Reservas: +54 351 155503986
                        </Typography>
                        <Typography
                            sx={{
                                display: 'flex', 
                                alignItems: 'center',
                                fontFamily: "'Poppins', sans-serif",
                            }}
                        >
                            <WhatsAppIcon sx={{ mr: 1 }} />
                            <Link
                                href="https://wa.me/3512021498"
                                target="_blank"
                                color="inherit"
                                underline="none"
                            >
                                +54 351 152021498
                            </Link>
                        </Typography>
                        <Typography
                            sx={{
                                display: 'flex', 
                                alignItems: 'center',
                                fontFamily: "'Poppins', sans-serif",
                            }}
                        >
                            <EmailIcon sx={{ mr: 1 }} />
                            <Link
                                href="mailto:migueltorti@yahoo.com.ar"
                                color="inherit"
                                underline="none"
                            >
                                migueltorti@yahoo.com.ar
                            </Link>
                        </Typography>
                    </Box>
                </Box>
            </Box>

            {/* Sección de redes sociales */}
            <Box sx={{ textAlign: 'center', mb: 0}}>
                <IconButton color="inherit"
                component="a"
                href="https://www.instagram.com/complejo_florserrana/" // Reemplaza el número por el tuyo
                target="_blank" 
                sx={{ mr: -1 }}>
                    <InstagramIcon />
                </IconButton>
                <IconButton color="inherit">
                    <Facebook />
                </IconButton>
            </Box>

            {/* Sección de copyright */}
            <Typography variant="body2" align="center">
                © {new Date().getFullYear()} Complejo Flor Serrana. Todos los derechos reservados.
            </Typography>
            <Typography variant="body2" align="center">
    
            </Typography>
        </Box>
    );
}

export default Footer;
