import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import coche from '../imagenes/servicios/coche.png'; // Iconos de servicios
import piscina from '../imagenes/servicios/piscina.png';
import niños from '../imagenes/servicios/niños.png';
import ejercicio from '../imagenes/servicios/ejercicio.png';
import espacio from '../imagenes/servicios/espacio.png';
import futbol from '../imagenes/servicios/futbol.png';
import voley from '../imagenes/servicios/voley.png';
import parrilla from '../imagenes/servicios/parrilla.png';
import pingpong from '../imagenes/servicios/pingpong.png';

const servicios = [
  { nombre: 'Amplios espacios verdes', icono: espacio },
  { nombre: 'Piscina', icono: piscina },
  { nombre: 'Cancha de Fútbol 7', icono: futbol },
  { nombre: 'Cancha de Volley', icono: voley },
  { nombre: 'Estacionamiento', icono: coche },
  { nombre: 'Espacio para niños', icono: niños },
  { nombre: 'Espacio para entrenar', icono: ejercicio },
  { nombre: 'Parrilla', icono: parrilla },
  { nombre: 'Ping Pong', icono: pingpong },
];

function Servicios() {
  return (
    <Box sx={{ mt: 4, textAlign: 'center' }}>
      <Typography variant="h4" sx={{ mb: 4, fontFamily: "'Roboto', sans-serif", color: '#555555',   textTransform: 'uppercase', fontSize: '2rem' }}>
        Servicios
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {servicios.map((servicio, index) => (
          <Grid item xs={12} sm={2} md={3} mr={3} key={index}>
            <Box sx={{ textAlign: 'center' }}>
              <img
                src={servicio.icono}
                alt={servicio.nombre}
                style={{ width: '40px', height: '40px', marginBottom: '10px' }}
              />
              <Typography variant="body1" sx={{ fontFamily: "'Roboto', sans-serif", color: '#555555' }}>
                {servicio.nombre}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Servicios;
