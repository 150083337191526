import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import video2 from '../imagenes/video2.mp4';

const mediaItems = [
  { type: 'video', src: video2 },
];

function Carrusel() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    fade: true,
  };

  return (
    <div
      style={{
        position: 'relative', // Permite que el carrusel ocupe todo el espacio
        width: '100vw',
        height: '100vh',
        overflow: 'hidden', // Previene barras de desplazamiento
      }}
    >
      <Slider {...settings}>
        {mediaItems.map((item, index) => (
          <div
            key={index}
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden', // Previene que los elementos desborden
            }}
          >
            {item.type === 'image' ? (
              <img
                src={item.src}
                alt={`Media ${index + 1}`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover', // Asegura que las imágenes llenen el espacio
                }}
              />
            ) : (
              <video
                src={item.src}
                autoPlay
                muted
                loop
                playsInline
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover', // Ajusta el video para que llene todo el espacio
                }}
              />
            )}
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Carrusel;
